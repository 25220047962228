<script>
  export default{
    components: {
      name: "jackie"
    }
  };
</script>

<template>

  <div class="bg-white overflow-hidden">
    <div class="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <div class="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
      <div class="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
        <div>

          <h3 class="mt-2 text-3xl leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">Meet Jackie Wichert, LCSW</h3>
        </div>
      </div>
      <div class="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
        <div class="relative lg:row-start-1 lg:col-start-2">
          <svg class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
            <defs>
              <pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
          </svg>
          <div class="relative text-base mx-auto max-w-prose lg:max-w-none">
            <figure>
              <div class="aspect-w-12 aspect-h-5 lg:aspect-none">
                <img class="rounded-lg shadow-lg object-cover object-center" src="https://i.postimg.cc/85SzkjN2/jackie-1.png" alt="Jackie Wichert" width="1184" height="1376">
              </div>
            </figure>
          </div>
        </div>
        <div class="mt-8 lg:mt-0">
          <div class="text-base max-w-prose mx-auto lg:max-w-none">
            <p class="text-lg text-gray-500">I am a Licensed Clinical Psychotherapist and have been practicing in the Greater Milwaukee area since 2006. My education includes an undergraduate degree in Psychology from UW-La Crosse and a masters degree in Clinical Social Work from UW-Milwaukee.  I have extensive experience
            working in psychiatric treatment hospitals and outpatient mental health clinics. I work with children (8 and older), adolescents,
            and adults. My specialities include treatment of anxiety disorders, depression, self-esteem, trauma, and navigating life stressors, especially parental stress.</p><br>
            <p class="text-lg text-gray-500">My approach to therapy is one of authenticity and realness. I believe that for inner growth and wellness to be achieved, you need
            to trust and feel comfortable with your therapist. Therefore, I provide a warm, welcoming, and non-judgemental space that allows you
            to quickly feel comfortable and safe so you can process and discuss difficult topics.</p><br>
            <p class="text-lg text-gray-500">My goal is to help you explore, process, and help resolve the obstacles life may present. I will help you utilize your strengths
            to find hope when feeling helpless and gain control when feeling lost and out of control.</p><br>
            <p class="text-lg text-gray-500">On a more personal note, I am married, and a mom of 3 kids and an adorable golden retriever, Winnie. In my free time I enjoy working out and just being outside (my stress relievers, because parenting can be hard 🙂)</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>

</style>




